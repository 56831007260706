import { Component, OnInit, Input } from '@angular/core';
import { LogoService } from '../data/logo.service';
import { BreakpointObserver } from '@angular/cdk/layout'

@Component({
  selector: 'app-product2rows',
  templateUrl: './product2rows.component.html',
  styleUrls: ['./product2rows.component.css']
})
export class Product2rowsComponent implements OnInit {
  
  @Input() prodId: any;

  data: any;
  logo :any;
  device : string;

  constructor(private logos: LogoService ,private breakpointObserver: BreakpointObserver) { 
	
	// mobile portrait
	this.breakpointObserver.observe([
      '(orientation: portrait)'
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'Portrait';
      }
    });

	// mobile landscape
		this.breakpointObserver.observe([
      '(orientation: landscape)'
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'Landscape';
      }
    });
	
}

  ngOnInit(): void {
    this.getData();

    this.logo = this.data[this.prodId];
  }

  getData(): void {
    this.logos.getLogoData().subscribe( data => this.data = data );
  }

  public findItemInArray(list: any,item: string): any {
	return list.find(x => x.viewport == item).imageUrl;
  }

}
