import { Component, OnInit } from '@angular/core';
import { LogoService } from '../data/logo.service';

@Component({
  selector: 'app-fivebox',
  templateUrl: './fivebox.component.html',
  styleUrls: ['./fivebox.component.css']
})
export class FiveboxComponent implements OnInit {


  logo: any;

  constructor(private logos: LogoService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void{
    this.logos.getAboutusData().subscribe( logo => this.logo = logo );
    console.log(this.logo);
  }
}
