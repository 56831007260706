import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomepageComponent } from './homepage/homepage.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';

import { ComponentsModule } from '../../components/components.module';

@NgModule({
  declarations: [ HomepageComponent],
  imports: [
        CommonModule,
        NgbModule,
        ComponentsModule,
        AgmCoreModule.forRoot({
            apiKey: 'YOUR_KEY_HERE'
        }),
        RouterModule
  ],
    exports: [
        HomepageComponent
    ]
})
export class HomepageModule { }

