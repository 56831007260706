import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MortgageComponent } from './mortgage/mortgage.component';

import { ComponentsModule } from '../../components/components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';


@NgModule({
  declarations: [MortgageComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    NgbModule,
    AgmCoreModule.forRoot({
            apiKey: 'YOUR_KEY_HERE'
        }),
    RouterModule
  ]
})
export class MortgageModule { }
