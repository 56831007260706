import { Component, OnInit ,ViewChild } from '@angular/core';
import { FcproductService } from '../data/fcproduct.service';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface} from 'ngx-swiper-wrapper';
import { BreakpointObserver ,Breakpoints} from '@angular/cdk/layout'

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

  products3: any[];
  device : string;
  public show: boolean = true;

  public type: string = 'directive';

  public disabled: boolean = false;

  public config: SwiperConfigInterface = {
    a11y: true,
    direction: 'horizontal',
    slidesPerView: 1,
	loop:true,
    centeredSlides: true,
    autoplay: false,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    effect: 'coverflow',
    grabCursor: true,
    coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows : true,
      },
    cubeEffect: {
        shadow: true,
        slideShadows: true,
        shadowOffset: 20,
        shadowScale: 0.94,
    },
    pagination: true
  };

 
  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;


  constructor(private productService: FcproductService,private breakpointObserver: BreakpointObserver) { 
	
	// mobile portrait
	this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'HandsetPortrait';
      }
    });

	// mobile landscape
		this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'HandsetLandscape';
      }
    });
	// Medium 
		this.breakpointObserver.observe([
      Breakpoints.Medium
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'Medium';
      }
    });

	// Tablet Potrait 
		this.breakpointObserver.observe([
      Breakpoints.TabletPortrait
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'TabletPortrait';
      }
    });

	// Tablet Landscape 
		this.breakpointObserver.observe([
      Breakpoints.TabletLandscape
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'TabletLandscape';
      }
    });


	// Large 
		this.breakpointObserver.observe([
      Breakpoints.Large
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'Large';
      }
    });
	// XLarge 
		this.breakpointObserver.observe([
      Breakpoints.XLarge
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'XLarge';
      }
    });

}

  ngOnInit(): void {
    this.getProducts();

  }

   getProducts(): void{
    this.productService.getProducts().subscribe( products => this.products3 = products );
  }

  public findItemInArray(list: any,item: string): any {
	return list.find(x => x.viewport == item);
  }

  public onIndexChange(index: number): void {
    console.log('Swiper index: ', index);
  }

  public onSwiperEvent(event: string): void {
    console.log('Swiper event: ', event);
  }

}
