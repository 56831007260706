import { Component, OnInit,Input } from '@angular/core';
import { LogoService } from '../data/logo.service';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css']
})
export class TextComponent implements OnInit {
  
  @Input() prodId: any;

  data: any;
  logo :any;

  constructor(private logos: LogoService) { }

  ngOnInit(): void {
    this.getData();

    this.logo = this.data[this.prodId];
  }

    getData(): void {
    this.logos.getLogoData().subscribe( data => this.data = data );
  }
}
