import { Component, OnInit } from '@angular/core';
import { LogoService } from '../data/logo.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  logo: any;

  constructor(private logos: LogoService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.logos.getLogoData().subscribe( logo => this.logo = logo );
  }
}
