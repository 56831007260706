import { Component, OnInit,Input  } from '@angular/core';
import { FcproductService } from '../data/fcproduct.service';
import { BreakpointObserver ,Breakpoints} from '@angular/cdk/layout'
 
@Component({
  selector: 'app-imagetext',
  templateUrl: './imagetext.component.html',
  styleUrls: ['./imagetext.component.css']
})
export class ImagetextComponent implements OnInit {

  @Input() prodId: String;

  device : string;

  products: any[];
  selectedProduct: any;

  constructor(private productService: FcproductService,private breakpointObserver: BreakpointObserver) { 
	
	// mobile portrait
	this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'HandsetPortrait';
      }
    });

	// mobile landscape
		this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'HandsetLandscape';
      }
    });

	// Medium 
		this.breakpointObserver.observe([
      Breakpoints.Medium
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'Medium';
      }
    });

	// Tablet Potrait 
		this.breakpointObserver.observe([
      Breakpoints.TabletPortrait
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'TabletPortrait';
      }
    });

	// Tablet Landscape 
		this.breakpointObserver.observe([
      Breakpoints.TabletLandscape
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'TabletLandscape';
      }
    });


	// Large 
		this.breakpointObserver.observe([
      Breakpoints.Large
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'Large';
      }
    });
 
	// XLarge 
		this.breakpointObserver.observe([
      Breakpoints.XLarge
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'XLarge';
      }
    });

}

  ngOnInit(): void {
    this.getProducts();

    this.selectedProduct = this.products.find(x => x.id == this.prodId);
  }

  getProducts(): void {
    this.productService.getProducts().subscribe( products => this.products = products );
  }

  public findItemInArray(list: any,item: string): any {
	return list.find(x => x.viewport.indexOf(item) != -1 ).imageUrl;
	}
}
