import { Component, OnInit } from '@angular/core';
import { FcproductService } from '../data/fcproduct.service';
import { BreakpointObserver } from '@angular/cdk/layout'

@Component({
  selector: 'app-product4',
  templateUrl: './product4.component.html',
  styleUrls: ['./product4.component.css']
})
export class Product4Component implements OnInit {

   products: any[];
   products1: any[];
   products2: any[];
   device : string;

  constructor(private productService: FcproductService ,private breakpointObserver: BreakpointObserver) {
	
		// mobile portrait
	this.breakpointObserver.observe([
      '(orientation: portrait)'
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'Portrait';
      }
    });

	// mobile landscape
		this.breakpointObserver.observe([
      '(orientation: landscape)'
    ]).subscribe(result => {
      if (result.matches) {
		this.device = 'Landscape';
      }
    });

 }

  ngOnInit(): void {
    this.getProducts();
    this.products1  =this.products;
  }

  getProducts(): void{
    this.productService.getProducts().subscribe( products => this.products = products );
  }

  public findItemInArray(list: any,item: string): any {
	return list.find(x => x.viewport == item).imageUrl;
  }

}
