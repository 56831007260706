import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';

import { ComponentsModule } from '../../components/components.module';
import { AboutUsComponent } from './about-us/about-us.component';


@NgModule({
  declarations: [AboutUsComponent],
  imports: [
        CommonModule,
        NgbModule,
        ComponentsModule,
        AgmCoreModule.forRoot({
            apiKey: 'YOUR_KEY_HERE'
        }),
        RouterModule

  ]
})
export class AboutusModule { }
