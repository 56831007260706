import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomepageComponent } from './pages/homepage/homepage/homepage.component';
import { FirsttimebuyerComponent } from './pages/firsttimebuyer/firsttimebuyer/firsttimebuyer.component';
import { AboutUsComponent } from './pages/aboutus/about-us/about-us.component';
import { ContactusComponent } from './pages/contactus/contactus/contactus.component';
import { MortgageComponent } from './pages/mortgage/mortgage/mortgage.component';
import { RemortgageComponent } from './pages/remortgage/remortgage/remortgage.component';
import { BuytoletComponent } from './pages/buytolet/buytolet/buytolet.component';
import { InsuranceComponent } from './pages/insurance/insurance/insurance.component';

const routes: Routes =[

    { path: 'homepage',             component: HomepageComponent },
    { path: 'firsttimebuyer',             component: FirsttimebuyerComponent },
    { path: '',             component: HomepageComponent },
    { path: 'about-us',             component: AboutUsComponent },
    { path: 'contact-us',             component: ContactusComponent },
    { path: 'mortgage',             component: MortgageComponent },
    { path: 're-mortgage',             component: RemortgageComponent },
    { path: 'buy-to-let',             component: BuytoletComponent },
    { path: 'insurance',             component: InsuranceComponent }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes,{
          useHash: true
        })
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
