import { Component, OnInit,Input } from '@angular/core';
import { FcproductService } from '../data/fcproduct.service';



@Component({
  selector: 'app-product3',
  templateUrl: './product3.component.html',
  styleUrls: ['./product3.component.css']
})
export class Product3Component implements OnInit {
  @Input() prodId: String;

  products: any[];
  selectedProduct: any;

  constructor(private productService: FcproductService) { }

  ngOnInit(): void {
    this.getProducts();
    this.selectedProduct = this.products.find(x => x.id == this.prodId);
  }

  getProducts(): void {
    this.productService.getProducts().subscribe( products => this.products = products );
  }
}
