import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as data from './logos.json';

@Injectable({
  providedIn: 'root'
})
export class LogoService {

   logo: any = (data as any).default;

  constructor() { }

  getLogoData(): Observable<any> {
    return of(this.logo);
  }


  getAboutusData(): Observable<any> {
    return of(this.logo.aboutus);
  }
}
