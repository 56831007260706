import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuytoletComponent } from './buytolet/buytolet.component';

import { ComponentsModule } from '../../components/components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';


@NgModule({
  declarations: [BuytoletComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    NgbModule,
    AgmCoreModule.forRoot({
            apiKey: 'YOUR_KEY_HERE'
        }),
    RouterModule  ]
})
export class BuytoletModule { }
