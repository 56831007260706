import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { Product3Component } from './product3/product3.component';
import { Product4Component } from './product4/product4.component';
import { ClientslogoComponent } from './clientslogo/clientslogo.component';
import { CarouselComponent } from './carousel/carousel.component';
import { FiveboxComponent } from './fivebox/fivebox.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ImagetextComponent } from './imagetext/imagetext.component';
import { Product2rowsComponent } from './product2rows/product2rows.component';
import { TextComponent } from './text/text.component';
import { FooterComponent } from './footer/footer.component';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule } from '@angular/forms';
//import { client } from 'elasticemail-webapiclient';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};

@NgModule({
  declarations: [Product3Component, Product4Component, ClientslogoComponent, CarouselComponent, FiveboxComponent, ContactUsComponent, ImagetextComponent, Product2rowsComponent, TextComponent, FooterComponent],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    AgmCoreModule.forRoot({
        apiKey: 'YOUR_KEY_HERE'
    }),
	SwiperModule,
    FlexLayoutModule,
	LayoutModule,
	FormsModule
  ],
    exports: [
        Product3Component,
        Product4Component,
        ClientslogoComponent,
        CarouselComponent,
        FiveboxComponent,
        ContactUsComponent,
        ImagetextComponent,
        Product2rowsComponent,
        TextComponent,
        FooterComponent
    ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }]
})
export class ComponentsModule { }
export { Product3Component } from './product3/product3.component';
export { Product4Component } from './product4/product4.component';
export { ClientslogoComponent } from './clientslogo/clientslogo.component';
export { CarouselComponent } from './carousel/carousel.component';
export { FiveboxComponent } from './fivebox/fivebox.component';
export { ContactUsComponent } from './contact-us/contact-us.component';
export { ImagetextComponent } from './imagetext/imagetext.component';
export { Product2rowsComponent } from './product2rows/product2rows.component';
export { TextComponent } from './text/text.component';
export { FooterComponent } from './footer/footer.component';