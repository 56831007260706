import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { HomepageModule } from './pages/homepage/homepage.module';
import { FirsttimebuyerModule } from './pages/firsttimebuyer/firsttimebuyer.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { ComponentsModule } from './components/components.module';
import { AboutusModule } from './pages/aboutus/aboutus.module';
import { ContactusModule } from './pages/contactus/contactus.module';
import { MortgageModule } from './pages/mortgage/mortgage.module';
import { RemortgageModule } from './pages/remortgage/remortgage.module';
import { BuytoletModule } from './pages/buytolet/buytolet.module';
import { InsuranceModule } from './pages/insurance/insurance.module';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';

import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'https://freedom-circle.firebaseapp.com/' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#EEEEEE'
    },
    button: {
      background: '#151f20'
    }
  },
  layout:'basic',
  position:'top',
  theme: 'classic',
  type: 'info'
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent
    ],
    imports: [
        NgcCookieConsentModule.forRoot(cookieConfig),
        BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        HomepageModule,
        FirsttimebuyerModule,
        ComponentsModule,
        AboutusModule,
        ContactusModule,
        MortgageModule,
        RemortgageModule,
        BuytoletModule,
        InsuranceModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: 'en'
        }
        )
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
